
import Main from '@/layout/main.vue'
import Blank from '@/layout/blank.vue'
const routes = [{
  path: '/setting',
  name: 'setting',
  meta: { title: '设置' },
  redirect: '/setting/platform/config',
  component: Main,
  children: [
    {
      path: '/setting/platform/config',
      name: 'platform',
      meta: {
        title: '平台设置',
        parentPath: '/setting',
        icon: 'icon_set_store'
      },
      component: Blank,
      redirect: '/setting/platform/config',
      children: [{
        path: '/setting/platform/config',
        name: 'config',
        meta: {
          title: '平台信息',
          parentPath: '/setting',
          permission: ['view']
        },
        component: () => import('@/views/setting/platform/config.vue')
      }, {
        path: '/setting/platform/keep_on_record',
        name: 'keep_on_record',
        meta: {
          title: '备案信息',
          parentPath: '/setting',
          permission: ['view']
        },
        component: () => import('@/views/setting/platform/keep_on_record.vue')
      }
        // {
        //   path: '/setting/game_user/tencent_reward_setting',
        //   name: 'tencent_reward_setting',
        //   meta: {
        //     title: '腾讯激励设置',
        //     parentPath: '/setting',
        //     permission: ['view']
        //   },
        //   component: () => import('@/views/setting/platform/tencent_reward_setting.vue')
        // },
        // {
        //   path: '/setting/game_user/kuaishou_reward_setting',
        //   name: 'kuaishou_reward_setting',
        //   meta: {
        //     title: '快手激励设置',
        //     parentPath: '/setting',
        //     permission: ['view']
        //   },
        //   component: () => import('@/views/setting/platform/kuaishou_reward_setting.vue')
        // },
        // {
        //   path: '/setting/game_user/baidu_reward_setting',
        //   name: 'baidu_reward_setting',
        //   meta: {
        //     title: '百度激励设置',
        //     parentPath: '/setting',
        //     permission: ['view']
        //   },
        //   component: () => import('@/views/setting/platform/baidu_reward_setting.vue')
        // }
      ]
    },
    {
      path: 'game_config/setting',
      name: 'game_config_setting',
      meta: {
        title: '游戏设置',
        parentPath: '/setting',
        moduleName: 'game_config',
        icon: 'icon_fenxiao_set'
      },
      component: Blank,
      children: [
        {
          path: '/game/base_setting',
          name: 'game_base_setting',
          meta: {
            title: '基础设置',
            parentPath: '/setting',
           // moduleName: 'game_config_setting',
            permission: ['view']
          },
          component: () => import('@/views/setting/platform/game_base_setting.vue')
        }, {
          path: '/game_topic/topic_lists',
          name: 'topic_lists',
          meta: {
            title: '题目管理',
            parentPath: '/setting',
            icon: 'icon_fenxiao_goods',
            permission: ['view']
          },
          component: () => import('@/views/setting/platform/topic_lists.vue')
        }, {
          path: '/game_topic/topic_edit',
          name: 'topic_edit',
          meta: {
            hidden: true,
            title: '题目编辑',
            parentPath: '/setting',
          },
          component: () => import('@/views/setting/platform/topic_edit.vue')
        }, {
          path: '/setting/game_topic/category_lists',
          name: 'game_topic_category_lists',
          meta: {
            title: '题目专题',
            parentPath: '/setting',
            icon: 'icon_notice',
            permission: ['view']
          },
          component: () => import('@/views/setting/platform/topic_category_lists.vue')
        },
        // {
        //   path: '/game_code_bits/code_bits_lists',
        //   name: 'code_bits_lists',
        //   meta: {
        //     title: '代码位管理',
        //     parentPath: '/setting',
        //     icon: 'icon_fenxiao_goods',
        //     permission: ['view']
        //   },
        //   component: () => import('@/views/setting/platform/code_bits_lists.vue')
        // }, {
        //   path: '/game_code_bits/code_bits_edit',
        //   name: 'code_bits_edit',
        //   meta: {
        //     hidden: true,
        //     title: '代码位编辑',
        //     parentPath: '/setting',
        //   },
        //   component: () => import('@/views/setting/platform/code_bits_edit.vue')
        // },
         {
          path: '/setting/game_user/protocol_setting',
          name: 'protocol_setting',
          meta: {
            title: '政策协议',
            parentPath: '/setting',
            permission: ['view']
          },
          component: () => import('@/views/setting/platform/protocol_setting.vue')
        }, {
          path: '/setting/game_help/lists',
          name: 'game_help',
          meta: {
            title: '帮助管理',
            parentPath: '/setting',
            icon: 'icon_notice',
            permission: ['view']
          },
          component: () => import('@/views/setting/platform/lists.vue')
        }, {
          path: '/setting/game_help/game_help_edit',
          name: 'game_help_edit',
          meta: {
            hidden: true,
            title: '帮助管理',
            parentPath: '/setting',
            permission: ['view']
          },
          component: () => import('@/views/setting/platform/game_help_edit.vue')
        }, {
          path: '/setting/game_help/category_lists',
          name: 'category_lists',
          meta: {
            title: '帮助分类',
            parentPath: '/setting',
            icon: 'icon_notice',
            permission: ['view']
          },
          component: () => import('@/views/setting/platform/category_lists.vue')
        }, {
          path: '/setting/game_user/csj_reward_setting',
          name: 'csj_reward_setting',
          meta: {
            title: '激励设置',
            parentPath: '/setting',
            permission: ['view']
          },
          component: () => import('@/views/setting/platform/csj_reward_setting.vue')
        },
        {
          path: '/setting/game_ping/lists',
          name: 'game_ping',
          meta: {
            title: 'Ping列表',
            parentPath: '/setting',
            icon: 'icon_notice',
            permission: ['view']
          },
          component: () => import('@/views/setting/platform/game_ping/lists.vue')
        },
        {
          path: '/setting/game_ping/black_lists',
          name: 'game_ping_black',
          meta: {
            title: 'Ping黑库',
            parentPath: '/setting',
            icon: 'icon_notice',
            permission: ['view']
          },
          component: () => import('@/views/setting/platform/game_ping/black_lists.vue')
        },
        {
          path: '/setting/game_cheats/lists',
          name: 'game_cheats_lst',
          meta: {
            title: 'APP黑库',
            parentPath: '/setting',
            icon: 'icon_notice',
            permission: ['view']
          },
          component: () => import('@/views/setting/platform/game_cheats/lists.vue')
        },
        {
          path: '/setting/game_cheats/category_lists',
          name: 'game_cheats_category_lists',
          meta: {
            title: 'APP黑库分类',
            parentPath: '/setting',
            icon: 'icon_notice',
            permission: ['view']
          },
          component: () => import('@/views/setting/platform/game_cheats/category_lists.vue')
        },
        {
          path: '/setting/game_accessibility/lists',
          name: 'game_accessibility_lists',
          meta: {
            title: '无障碍黑库',
            parentPath: '/setting',
            icon: 'icon_notice',
            permission: ['view']
          },
          component: () => import('@/views/setting/platform/game_accessibility/lists.vue')
        },
        {
          path: '/setting/game_ip/lists',
          name: 'game_ip_lists',
          meta: {
            title: 'IP黑库',
            parentPath: '/setting',
            icon: 'icon_notice',
            permission: ['view']
          },
          component: () => import('@/views/setting/platform/game_ip/lists.vue')
        },
        {
          path: '/setting/game_hash/lists',
          name: 'game_hash_lists',
          meta: {
            title: '定位hash黑库',
            parentPath: '/setting',
            icon: 'icon_notice',
            permission: ['view']
          },
          component: () => import('@/views/setting/platform/game_hash/lists.vue')
        },
        {
          path: '/setting/game_device/lists',
          name: 'game_device_lists',
          meta: {
            title: '设备ID黑库',
            parentPath: '/setting',
            icon: 'icon_notice',
            permission: ['view']
          },
          component: () => import('@/views/setting/platform/game_device/lists.vue')
        },
        {
          path: '/setting/game_ip_address/lists',
          name: 'game_ip_address_lists',
          meta: {
            title: 'IP归属地黑库',
            parentPath: '/setting',
            icon: 'icon_notice',
            permission: ['view']
          },
          component: () => import('@/views/setting/platform/game_ip_address/lists.vue')
        },
        {
          path: '/setting/game_wx_user_account/lists',
          name: 'game_wx_user_account_lists',
          meta: {
            title: '微信账号黑库',
            parentPath: '/setting',
            icon: 'icon_notice',
            permission: ['view']
          },
          component: () => import('@/views/setting/platform/game_wx_user/lists.vue')
        },
        {
          path: '/setting/game_wx_user_nickname/lists',
          name: 'game_wx_user_nickname_lists',
          meta: {
            title: '微信昵称黑库',
            parentPath: '/setting',
            icon: 'icon_notice',
            permission: ['view']
          },
          component: () => import('@/views/setting/platform/game_wx_nickname/lists.vue')
        },
        {
          path: '/setting/game_black_withdrawal_account/lists',
          name: 'game_black_withdrawal_account_lists',
          meta: {
            title: '支付宝提现账号黑库',
            parentPath: '/setting',
            icon: 'icon_notice',
            permission: ['view']
          },
          component: () => import('@/views/setting/platform/game_black_withdrawal_account/lists.vue')
        },
        {
          path: '/setting/game_quark_shortplay/lists',
          name: 'game_quark_shortplay_lists',
          meta: {
            title: '夸克短剧列表',
            parentPath: '/setting',
            icon: 'icon_notice',
            permission: ['view']
          },
          component: () => import('@/views/setting/platform/game_search/quark_shortplay_list.vue')
        },
        {
          path: '/game_search/quark_shortplay/edit',
          name: 'edit',
          meta: {
            title: '编辑/添加',
            parentPath: '/setting',
            hidden: true,
            icon: 'icon_fenxiao_data',
            permission: ['view']
          },
          component: () => import('@/views/setting/platform/game_search/quark_shortplay_edit.vue')
        },
        {
          path: '/setting/game_quark_movie/lists',
          name: 'game_quark_movie_lists',
          meta: {
            title: '夸克影视列表',
            parentPath: '/setting',
            icon: 'icon_notice',
            permission: ['view']
          },
          component: () => import('@/views/setting/platform/game_search/quark_movie_list.vue')
        },
        {
          path: '/game_search/quark_movie/edit',
          name: 'edit',
          meta: {
            title: '编辑/添加',
            parentPath: '/setting',
            hidden: true,
            icon: 'icon_fenxiao_data',
            permission: ['view']
          },
          component: () => import('@/views/setting/platform/game_search/quark_movie_edit.vue')
        }
      ]
    },
    {
      path: '/setting/payment/config',
      name: 'setting_pay_config',
      meta: {
        title: '支付配置',
        icon: 'icon_set_pay',
        parentPath: '/setting',
        permission: ['view']
      },
      component: () => import('@/views/setting/payment/pay_config.vue')
    },
    {
      path: '/setting/payment/pay_config_edit',
      name: 'setting_pay_config_edit',
      meta: {
        hidden: true,
        title: '支付方式设置',
        parentPath: '/setting',
        prevPath: '/setting/payment/config'
      },
      component: () => import('@/views/setting/payment/pay_config_edit.vue')
    },
    {
      path: '/setting/service/service',
      name: 'service',
      meta: {
        title: '客服设置',
        parentPath: '/setting',
        icon: 'icon_notice_buyer'
      },
      component: Blank,
      redirect: '/setting/service/service',
      children: [{
        path: '/setting/service/service',
        name: 'config',
        meta: {
          title: '联系客服',
          parentPath: '/setting',
          permission: ['view']
        },
        component: () => import('@/views/setting/service/service.vue')
      }]
    }, {
      path: '/setting/security/account',
      name: 'security',
      meta: {
        title: '安全设置',
        parentPath: '/setting',
        icon: 'icon_set_quanxian'
      },
      component: Blank,
      redirect: '/setting/security/account',
      children: [{
        path: '/setting/security/account',
        name: 'journal',
        meta: {
          title: '修改密码',
          parentPath: '/setting',
          permission: ['view']
        },
        component: () => import('@/views/setting/security/account.vue')
      }]
    }, {
      path: '/setting/system_maintain/journal',
      name: 'system_maintain',
      meta: {
        title: '系统维护',
        parentPath: '/setting',
        icon: 'icon_set_weihu'
      },
      component: Blank,
      redirect: '/setting/system_maintain/journal',
      children: [{
        path: '/setting/system_maintain/journal',
        name: 'journal',
        meta: {
          title: '系统日志',
          parentPath: '/setting',
          permission: ['view']
        },
        component: () => import('@/views/setting/system_maintain/journal.vue')
      }, {
        path: '/setting/system_maintain/cache',
        name: 'cache',
        meta: {
          title: '系统缓存',
          parentPath: '/setting',
          permission: ['view']
        },
        component: () => import('@/views/setting/system_maintain/cache.vue')
      }, {
        path: '/setting/system_maintain/environment',
        name: 'environment',
        meta: {
          title: '系统环境',
          // hidden: true,
          parentPath: '/setting',
          permission: ['view']
        },
        component: () => import('@/views/setting/system_maintain/environment.vue')
      }, {
        path: '/setting/system_maintain/task',
        name: 'task',
        meta: {
          title: '定时任务',
          parentPath: '/setting',
          permission: ['view']
        },
        component: () => import('@/views/setting/system_maintain/task/lists.vue')
      }, {
        path: '/setting/system_maintain/task_edit',
        name: 'task_edit',
        meta: {
          hidden: true,
          title: '定时任务',
          parentPath: '/setting',
          permission: ['view']
        },
        component: () => import('@/views/setting/system_maintain/task/edit.vue')
      }]
    }, {
      path: '/setting/storage/storage',
      name: 'order',
      meta: {
        title: '存储设置',
        parentPath: '/setting',
        icon: 'icon_set_save'
      },
      component: Blank,
      redirect: '/setting/storage/storage',
      children: [{
        path: '/setting/storage/storage',
        name: 'setting_storage',
        meta: {
          title: '设置A',
          parentPath: '/setting',
          hidden: true
        },
        component: () => import('@/views/setting/storage/storage.vue')
      }, {
        path: '/setting/storage/index',
        name: 'setting_storage_index',
        meta: {
          title: '存储设置',
          parentPath: '/setting',
          permission: ['view']

        },
        component: () => import('@/views/setting/storage/index.vue')
      }]
    }
  ]
}]

export default routes
